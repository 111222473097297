<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="login">
        <card class="card-login card-white">
          <template slot="header">
            <div id="logo_header">
              <img src="/img/logo.png" alt="" />
            </div>
          </template>

          <div>
            <base-input v-validate="'required|email'" name="email" :error="emailError" v-model="model.email"
              placeholder="Email" addon-left-icon="tim-icons icon-email-85">
            </base-input>

            <base-input v-validate="'required|min:5'" name="password" :error="passwordError" v-model="model.password"
              type="password" placeholder="Senha" addon-left-icon="tim-icons icon-lock-circle">
            </base-input>

            <div class="col-md-12 text-danger px-0 mb-4" v-if="invalidCredentials">
              Email e/ou senha inválidos.
            </div>

            <div class="col-md-12 text-danger px-0 mb-4" v-if="serverError">
              Ocorreu um erro, favor tentar mais tarde.
            </div>

            <!-- <router-link to="/forgot" class="text-right d-block">Esqueci minha senha</router-link> -->
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="primary" :disabled="loading" class="mb-3" size="lg" block>
              <i class="fas fa-circle-notch" v-if="loading"></i>
              Entrar
            </base-button>

            <div class="pull-right">
              <h6><a href="#v" class="link footer-link">Versão 1.2.5</a></h6>
            </div>
          </div>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        email: '',
        password: '',
        submit: false
      },
      loading: false,
      invalidCredentials: false,
      serverError: false
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },

    async login() {
      this.model.submit = true
      const isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        this.invalidCredentials = false
        this.serverError = false
        this.loading = true
        const isLoged = await this.$jwt.accessToken(this.model)
        if (isLoged === 200) this.$router.push('/')
        if (isLoged !== 200) this.loading = false
        if (isLoged === 401) {
          this.invalidCredentials = true
          this.serverError = false
        }
        if (isLoged === 500) {
          this.invalidCredentials = false
          this.serverError = true
        }
      }
    }
  },
  computed: {
    emailError() {
      if (
        // eslint-disable-next-line no-useless-escape
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.email)
      ) {
        return
      }
      return this.model.submit ? 'O campo email é obrigatório' : ''
    },
    passwordError() {
      return (this.model.password === '' || this.model.password === null) &&
        this.model.submit
        ? 'O campo senha é obrigatório'
        : ''
    }
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.card-login .card-header {
  padding: 15px 0;
  overflow: hidden;
}

.fa-circle-notch {
  animation: loading 1.5s linear infinite;
  margin-right: 1rem;
  font-size: 1.1rem;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.login-page .card-login .card-header {
  padding: 15px 0;
}

#logo_header {
  height: 8rem;
  position: relative;
  margin-bottom: 1rem;
}

#logo_header img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);

}
</style>
